<template>
    <div>
        <!-- <van-nav-bar title="借支详情" left-text="返回" left-arrow @click-left="onClickLeft" /> -->

        <div class="borrowing">
           
            <!-- 在职信息 -->
            
            <!-- 借支申请 -->
            
            <!-- 驻厂审批信息 -->
            
            <!-- 财务放款信息 -->
            
            <!-- 冲销记录 -->
            <div class="block" v-for="item in list" :key="item.index">
                <h3>冲销记录详情</h3>
                <div class="content">
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.money}}元</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">还款时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.repayment_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销时间</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.info_time}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销状态</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.status}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">冲销类型</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.reverse_type}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">待冲销金额</div>
                        </div>
                        <div class="right">
                            <div class="text">{{item.advanced_payment_money}}元</div>
                        </div>
                    </div>
                    <div class="cell" v-if="item.note">
                        <div class="left">
                            <div class="title">备注</div>
                        </div>
                        <div class="right">
                            <pre class="text">{{item.note}}</pre>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            details: {},
            borrowing_id: 0,
            list:[],
            type: 1,
        };
    },
    mounted() {
        window.scrollTo(0,0)
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.borrowing_id = this.$route.query.borrowing_id
        this.getDetails()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        // 查看全部图片
        lookImg(array) {
            ImagePreview(array);
        },
        getDetails() {
            var data = {
                team_id: localStorage.getItem('team_id'),
                advanced_payment_id: this.borrowing_id
            }
            this.Request('client/advanced_payment/info','get',data).then(res => {
                if (res.status == 0) {
                    this.details = res.data
                    this.list = res.data.payment_reverse_info
                }
            })
        },
    }
}
</script>
<style scoped>
.borrowing {
    margin: 30px 30px 0px;
    /* padding-top: 120px; */
    text-align: left;
}
.block {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: left;
}
.block .look {
    color: #4c94f6;
}
/* input输入框样式 */
.cell {
    display: flex;
    /* border-bottom: 2px solid #f8f8f8; */
    padding: 12px 0;
    position: relative;
    line-height: 40px;
    align-items: center;
    
}
h3{font-size: 30px;margin-bottom: 20px;}
.cell .left {
    display: flex;
    font-size: 26px !important;
    width: 30%;
    color: #999;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}

.cell .right {
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.cell .right .text {
    width: 100%;
    /* text-align: right; */
    overflow: hidden;
    font-size: 26px !important;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.block .cell:last-of-type {
    border: 0;
}
.title_text{padding: 20px 0;}
/* .cell{display: block;} */
/* .cell .right{width: 100%;text-align: left;margin-top: 16px;} */
.cell .right .text{text-align: left;white-space: pre-wrap;word-wrap: break-word;}

</style>