import { render, staticRenderFns } from "./writeDetails.vue?vue&type=template&id=120b1cb8&scoped=true&"
import script from "./writeDetails.vue?vue&type=script&lang=js&"
export * from "./writeDetails.vue?vue&type=script&lang=js&"
import style0 from "./writeDetails.vue?vue&type=style&index=0&id=120b1cb8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120b1cb8",
  null
  
)

export default component.exports